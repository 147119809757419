import React from "react";
import img2 from "../../../assets/img2.svg";
import img3 from "../../../assets/img3.svg";
import img4 from "../../../assets/img4.svg";
const Card3 = () => {
  const Cards = [
    {
      img: img2,
      color: '#5B72EE',
      title: "Comprehensive guidance",
      description:
        "A variety of evaluations are available on InfoArbol career counseling online to help you identify your areas of strength and growth.  These insights assist you in making well-informed decisions regarding your career, guaranteeing that you are headed in the proper direction from the start.",
    },
    {
      img: img3,
      color: '#00CBB8',
      title: "Extensive Options",
      description:
        "Our website is a treasure of valuable resources, including articles , videos, and guides on a wide range of industries and professions. Wе keep you up-to-date with the latest trends and job market demands, ensuring you have all the information you nееd to succeed.",
    },
    {
      img: img4,
      color: '#29B9E7',
      title: "Improved Plans",
      description:
        "For any of your career questions, Career counselling has the solutions. We work dedicatedly to create a carееr plan that aligns with your goals. InfoArbol career counselling for students can assist you in finding the ideal course of study, looking into internships, and getting ready for interviews",
    },
  ];
  return (
    <div className="w-fulll overflow-x-hidden bg-white">
      <div className="sm:w-[95%] md:w-[85%] mx-auto py-10">
        <div className="flex sm:flex-col sm:gap-4 md:gap-5 md:flex-row">
          {Cards.map((card, index) => (
            <div class="relative bg-[#112F69] text-[#DCE2EE] p-6 flex flex-col gap-8 rounded-[6px] mx-auto mt-8 text-center">
              <div  style={{ backgroundColor: card.color }}  class="absolute -top-8 left-1/2 transform -translate-x-1/2 rounded-full p-3 w-16 h-16 flex items-center justify-center">
                <img src={card.img} alt="Icon" class="w-full h-full" />
              </div>
              <h2 class="mt-10 text-xl font-semibold h-[30px]">{card.title}</h2>
              <p class=" sm:text-sm md:text-[18px] md:leading-7 sm:w-[90%] md:w-[80%] text-center mx-auto ">
              {card.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card3;
