import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
const FAQs = () => {
  const Tabs = [
    {
      Title: "How can career counseling benefit me?",
      Icon: "",
      Description:
        "Career counseling services is a process of professional guidance and support that assists individuals in exploring and making educated career options. It gives you significant insights into your abilities, interests, and values, assisting you in finding a career path that matches your strengths and aspirations. Job counseling is important because it can help you save time and effort by restricting your possibilities and enhancing your prospects of long-term job happiness.",
    },
    {
      Title: "InfoArbol Best Career Counselling partner",
      Icon: "",
      Description:
        "Career counseling in India can help you develop clarity about your career goals, explore other job possibilities, and evaluate your strengths and shortcomings. It can also assist you in developing successful job-search tactics, improving your interview abilities, and increasing your general marketability. Finally, career counseling services give you the ability to make well-informed decisions, increasing your chances of finding a happy and successful profession.",
    },
    {
      Title: "Who can benefit from career counseling in InfoArbol?",
      Icon: "",
      Description:
        "Individuals at all phases of their lives, including students, graduates, and professionals, benefit from career counseling in India. Career counseling after 10th may provide essential insights and assistance whether you are a high school student exploring career alternatives, a college graduate searching for guidance in entering the job market, or a professional seeking mid-career transition or development.",
    },
    {
      Title: "How do I get InfoArbol Career Counseling?",
      Icon: "",
      Description:
        "To connect with InfoArbol online counseling for career guidance, simply download the app from the Google Play store, select your professional path from the many available categories, and begin your adventure. or contact us by filling the form.",
    },
    {
      Title: "Future Trends and Innovations at InfoArbol",
      Icon: "",
      Description:
        "InfoArbol career counselling for students makes advantage of By providing novel solutions, technology is transforming career counseling. Individuals seeking career guidance benefit from the convenience and accessibility of virtual platforms, online examinations, and career exploration tools. These technological innovations have the potential to reach a larger audience and deliver individualized recommendations based on individual talents and interests.",
    },
    {
      Title: "Conclusion:",
      Icon: "",
      Description:
        "InfoArbol career guidance online is here to help you no matter what career you choose: Fashion, Science, Technology, Sports, Entertainment, Engineering, Maths and Finance, Medical Science, Business & Management. We are delighted to assist future stars like you.",
    },
    {
      Title: "Consumer Tech: Apps and Services",
      Icon: "",
      Description:
        "Encapsulates the idea that leveraging technological innovations can enhance financial processes, streamline operations, and improve overall efficiency Encapsulates the idea that leveraging technological innovations can enhance financial processes, streamline operations, and improve overall efficiency",
    },
  ];
  const [visibleTabs, setVisibleTabs] = useState(
    Array(Tabs.length).fill(false)
  );

  // Function to toggle visibility of a specific tab
  const toggleVisibility = (index) => {
    const newVisibleTabs = [...visibleTabs];
    newVisibleTabs[index] = !newVisibleTabs[index];
    setVisibleTabs(newVisibleTabs);
  };
  return (
    <div id="faqs" className="flex  place-content-center  flex-col font-lato w-full bg-white">
      <div className="md:w-[85%] sm:w-full md:rounded-[16px] sm:px-6 md:px-12 mx-auto py-12 bg-[#122246]  ">
        <div style={{borderBottom :'1px solid #CCCCCC'}} className="text-base font-normal block w-fit  my-4 leading-none  text-white">
          Expand All
        </div>
        {Tabs.map((tab, index) => (
          <div
            key={index}
            style={{borderTop:'1px solid #CCCCCC'}}
            className="sm:mt-4 md:mt-6 gap-8 flex relative flex-col w-full max-md:max-w-full"
          >
            <div className="flex place-content-center my-auto absolute right-0 top-4 z-0 flex-col w-5 h-5">
              <FontAwesomeIcon
                icon={faPlus}
                className="md:h-5 md:w-5 sm:h-4 sm:w-4 text-white"
                onClick={() => toggleVisibility(index)}
              />
            </div>
            <div className="py-3 w-full sm:text-[14px] md:text-lg font-normal leading-tight text-white uppercase max-md:pr-5 max-md:max-w-full">
              {tab?.Title}
            </div>
            {visibleTabs[index] && (
              <div className="w-full pb-4 -mt-6 sm:text-[10px] md:text-xs font-normal tracking-[1px] leading-[15px] text-white uppercase max-md:pr-5 max-md:max-w-full">
                {tab?.Description}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
