import React, { useEffect, useState } from "react";
import img24 from "../../assets/img24.svg";
import img31 from "../../assets/img31.svg";
import img32 from "../../assets/img32.svg";
import { Link } from "react-scroll";
import { easeLinear } from "easing-functions";
const Navbar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDivVisible, setIsDivVisible] = useState(false);
  const toggleDiv = () => {
    setIsDivVisible(!isDivVisible);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="absolute bg-transparent  w-full z-20 md:backdrop-blur-lg overflow-x-hidden">
        {windowWidth >= 768 ? (
          <div className="text-white py-2 justify-between items-center  flex flex-row gap-4 px-12">
            <div className="">
              <img srcSet={img24} className="h-[60px] w-[60px] " />
            </div>
            <div className="flex custom-navbar ">
              <Link
                to="home"
                smooth={easeLinear}
                duration={400}
                style={{  fontWeight: "600" }}
                className="nav-link cursor-pointer  text-[22px] font-semibold "
              >
                Home
              </Link>
              <Link
                to="about"
                smooth={easeLinear}
                duration={400}
                style={{  fontWeight: "600" }}
                className="nav-link text-[22px] cursor-pointer font-semibold "
              >
                About Us
              </Link>
              <Link
                to="services"
                smooth={easeLinear}
                duration={400}
                style={{fontWeight: "600" }}
                className="nav-link cursor-pointer  text-[22px] font-semibold "
              >
                Our Services
              </Link>
              <Link
                to="faqs"
                smooth={easeLinear}
                duration={400}
                style={{ fontWeight: "600" }}
                className="nav-link cursor-pointer  text-[22px] font-semibold "
              >
                FAQs
              </Link>
              <Link
                to="blog"
                smooth={easeLinear}
                duration={400}
                style={{ fontWeight: "600" }}
                className="nav-link cursor-pointer  text-[22px] font-semibold "
              >
                Blog
              </Link>
              <Link
                to="contact"
                smooth={easeLinear}
                duration={400}
                style={{fontWeight: "600" }}
                className="nav-link cursor-pointer  text-[22px] font-semibold "
              >
                Contact US
              </Link>
            </div>
          </div>
        ) : (
          <>
            <div className="text-white py-3 justify-between items-center  flex flex-row gap-2 px-3">
              <div className="">
                <img srcSet={img24} className="h-[70px] w-[70px] " />
              </div>
              <Link
                to="home"
                smooth={easeLinear}
                duration={400}
                style={{ fontSize: "18px", fontWeight: "600" }}
                className="nav-link cursor-pointer  text-[22px] font-semibold "
              >
                Home
              </Link>
              <Link
                to="about"
                smooth={easeLinear}
                duration={400}
                style={{ fontSize: "18px", fontWeight: "600" }}
                className="nav-link text-[22px] cursor-pointer font-semibold "
              >
                About Us
              </Link>
              <div className=" ">
                {isDivVisible ? (
                  <img
                    srcSet={img32}
                    className="cursor-pointer text-white "
                    onClick={toggleDiv}
                    alt="Close"
                  />
                ) : (
                  <img
                    srcSet={img31}
                    className="cursor-pointer"
                    onClick={toggleDiv}
                    alt="Open"
                  />
                )}
              </div>
            </div>
            {isDivVisible && (
              <div className="flex justify-end self-end place-content-end items-end text-white">
                <div className="flex bg-black w-[42%] mx-2 flex-col gap-2 px-3 py-3">
                  <Link
                    to="services"
                    smooth={easeLinear}
                    duration={400}
                    style={{ fontSize: "18px", fontWeight: "600" }}
                    className="nav-link cursor-pointer  text-[22px] font-semibold "
                  >
                    Our Services
                  </Link>
                  <Link
                    to="faqs"
                    smooth={easeLinear}
                    duration={400}
                    style={{ fontSize: "18px", fontWeight: "600" }}
                    className="nav-link cursor-pointer  text-[22px] font-semibold "
                  >
                    FAQs
                  </Link>
                  <Link
                    to="blog"
                    smooth={easeLinear}
                    duration={400}
                    style={{ fontSize: "18px", fontWeight: "600" }}
                    className="nav-link cursor-pointer  text-[22px] font-semibold "
                  >
                    Blog
                  </Link>
                  <Link
                    to="contact"
                    smooth={easeLinear}
                    duration={400}
                    style={{ fontSize: "18px", fontWeight: "600" }}
                    className="nav-link cursor-pointer  text-[22px] font-semibold "
                  >
                    Contact US
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Navbar;
