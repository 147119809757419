import React from "react";
import Card2 from "./Card2";
import Card3 from "./Card3";
import AboutUs from "../AboutUs";
import Card5 from "./Card5";
import OurService from "../OurServices";
import Card7 from "./Card7";
import Card8 from "./Card8";
import FAQs from "../FAQs";
import ContactUs from "../ContactUs";
import Card11 from "./Card11";
import Card12 from "./Card12";
import Card1 from "./Card1";
import Blog from "../Blog";
import Navbar  from "../Navbar";

const Main = () => {
  return (
    <div>
      <Navbar />
      <Card1 />
      <Card2 />
      <Card3 />
      <AboutUs />
      <Card5 />
      <OurService />
      <Card7 />
      <Card8 />
      <FAQs />
      <Blog />
      <ContactUs />
      <Card11 />
      <Card12 />
    </div>
  );
};

export default Main;
