import React from "react";
import img9 from "../../../assets/img9.svg";
import img10 from "../../../assets/img10.svg";
import img11 from "../../../assets/img11.svg";
import img12 from "../../../assets/img12.svg";
import img13 from "../../../assets/img13.svg";
import img14 from "../../../assets/img14.svg";
import img15 from "../../../assets/img15.svg";
import img16 from "../../../assets/img16.svg";
const Card8 = () => {
  const card = [
    {
      img: img9,
      title: "Engineering & Technology:",
      description:
        "Technology and innovation are reshaping industries around the globe, creating new opportunities for professionals. The rapidly growing sector of technology offers a bunch of opportunities for anyone wishing to make their mark in today’s modern world. There are various options available, including Software Development and Engineering, Data Science and Analytics, Cybersecurity and Information Technology, and many others.",
    },
    {
      img: img10,
      title: "Healthcare",
      description:
        "The increasing demand for healthcare professionals is a pressing issue that requires immediate attention. As the population continues to grow, so does the need for accessible and top-notch healthcare services. Various career paths, such as medical and nursing professions, allied health fields, alternative medicine, and holistic health, offer viable alternatives to meet this demand.",
    },
    {
      img: img11,
      title: "Management & Marketing",
      description:
        "Management and marketing are Continuously developing occupations that involve persons To sail through a complex world of start-ups and entrepreneurship. To compete in this competitive environment, professionals must have a thorough awareness of the complexities involved in starting and expanding a firm. Management and Leadership, Marketing and Sales, and Innovation and Start-up Culture are just a few of the possibilities accessible.",
    },
    {
      img: img12,
      title: "Creativity/ Art",
      description:
        "In today’s modern and highly competitive world, it is critical to recognize the immense worth of the creative and artistic industries. From visual arts to performing arts, these careers allow people to express themselves and pursue their interests in a variety of ways. Among the options are visual arts and design, performing arts and entertainment, and writing and publishing.",
    },
    {
      img: img13,
      title: "Science & Research",
      description:
        "Science and research offers a wide range of exciting opportunities for individuals passionate about discovering new knowledge and pushing the boundaries of human understanding. Whether it’s exploring the mysteries of the universe through astrophysics, unraveling the complexities of biology, or revolutionizing technology through computer science, scientific research provides a platform to turn curiosity into tangible breakthroughs.",
    },
    {
      img: img14,
      title: "Aerospace & Aviation",
      description:
        "Aerospace & Aviation opens up a world of possibilities for those who enjoy exploration, invention, and problem solving. This field’s professionals are in charge of designing, building, and repairing airplanes, as well as guaranteeing the safety and efficiency of air travel. Commercial airlines, military aviation, space exploration, and research and development are all parts of the aerospace industry.",
    },
    {
      img: img15,
      title: "Media & Entertainment",
      description:
        "Media and entertainment provides individuals with the exciting opportunity to work in a fast-paced profession focused on creativity, storytelling, and audience engagement. Journalism, television and film production, advertising, public relations, music production, and digital media are all possible careers in this industry. Professionals in these disciplines are in charge of developing content that informs, educates, entertains.",
    },
    {
      img: img16,
      title: "Law & Order",
      description:
        "Law & Order presents vast opportunities for individuals wanting to pursue a professional and fulfilling path. Working within the legal system, one can choose from various roles such as becoming a lawyer, judge, or law enforcement officer. As a lawyer, one has the power to advocate for justice by representing clients in court and providing legal counsel.",
    },
  ];
  return (
    <div className="flex flex-col  bg-white font-lato">
      <div className="flex flex-col gap-4 justify-center items-start py-10 w-[95%] mx-auto max-md:max-w-full">
        <div className="flex flex-col grow shrink items-center px-3.5 mx-auto text-center ">
          <div className="flex flex-col items-center mx-auto max-w-full">
            {/* <div className="text-12xl-3 sm:1/2 md:w-[45%] px-6 py-3 font-bold leading-10 text-[#5498FE] max-md:max-w-full">
              Expertise of Zeddlabz Across Key Industries
            </div> */}
            <div className="mt-2 text-2xl font-semibold font-lato tracking-[1px] text-black leading-7 md:w-2/3 max-md:max-w-full">
              Let me provide information on some of the career options among
              InfoArbol Career Counseling's 200+ categories.
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-1  md:grid-cols-4 gap-[10px]">
          {card.map((item, index) => (
            <div
              key={index}
              className="flex font-space-grotesk mt-4 rounded-[20px] bg-[#F6F3EE] flex-col px-[10px] py-2"
            >
              <img
                loading="lazy"
                srcSet={item.img}
                className="object-contain max-w-full rounded-lg "
              />
              <div className="mt-2 text-xl font-semibold text-[#1F1F1F]">
                {item.title}
              </div>
              <div className="mt-[10px] mb-4 text-[14px] text-[#505050]">
                {item.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card8;
