import React from "react";
import img17 from "../../assets/img17.svg";
import img18 from "../../assets/img18.svg";
import img33 from "../../assets/img33.svg";
import img34 from "../../assets/img34.svg";
const ContactUs = () => {
  return (
    <div id="contact" className="w-full overflow-x-hidden bg-gradient-to-b from-[#2249A0] to-[#122246]">
      <div className="flex sm:flex-col px-8 sm:py-12 md:py-12 md:flex-row">
        <div className="relative md:w-[30%] flex justify-center items-center place-content-center  text-center h-[300px]">
          <img
            srcSet={img17}
            className="absolute sm:bottom-[20%] md:bottom-0 left-[45%] md:h-[300px] md:w-[300px] sm:h-[250px] sm:w-[250px] transform -translate-x-1/2"
          />
          <img
            srcSet={img18}
            className="absolute sm:bottom-[20%] md:bottom-0 left-[60%] md:h-[300px] md:w-[300px] sm:h-[250px] sm:w-[250px]  transform -translate-x-1/2"
          />
        </div>

        <div className="md:w-[70%] justify-self-center gap-2 flex flex-col items-center justify-center text-center">
          <h1 className="text-[#FFFFFF] font-bold sm:text-[38px] md:text-[50px] text-center tracking-[4px] font-lato ">
            Download App Now
          </h1>
          <div className="flex flex-row gap-5 cursor-pointer">
          <div className="">
              <img srcSet={img34} className="sm:h-10 sm:w-18 md:h-[48px] md:w-[170px]" />
            </div>
            <div className="">
              <img srcSet={img33} className="sm:h-10 sm:w-18 md:h-[48px] md:w-[170px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
