import React from "react";

const Card2 = () => {
  return (
    <div className="bg-white w-full">
      <div className="sm:w-[95%] mx-auto text-center my-6  font-lato md:w-[70%] flex flex-col">
        <div className = "text-9xl-3 text-[#010514] font-bold gap-2 ">Our Mission</div>
        <div className = "text-lg text-[#010514CC]">
          Our mission is to help you find your potential and choose a career
          guidance after 10th that will give you fulfillment and prosperity to
          your plate. We believe that everyone deserves to have their dream job,
          and we are here to make that happen. We Aim to provide you with the
          information and confidence to make professional choices.
        </div>
      </div>
    </div>
  );
};

export default Card2;
