import React from "react";
import img19 from "../../../assets/img19.svg";
import img20 from "../../../assets/img20.svg";
import img21 from "../../../assets/img21.svg";
import img22 from "../../../assets/img22.svg";
import img23 from "../../../assets/img23.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
const Card11 = () => {
  return (
    <div className="w-full overflow-x-hidden font-poppins bg-white">
      <div className="flex sm:flex-col md:w-[95%] md:gap-12 py-12 sm:px-6 md:px-12 mx-auto md:flex-row">
        <div className="flex md:w-[35%]">
          <div className="bg-[#011C2B] rounded-[8px] py-9 w-full sm:px-4 md:px-8 flex gap-16 flex-col ">
            <div>
              <h1 className="text-white text-5xl">Contact Information</h1>
              <p className=" text-[#C8C8C8] ">
                Say something to start a live chat!
              </p>
            </div>
            <div className="flex sm:gap-5 md:gap-4 sm:text-mini md:text-[16px]  flex-col text-white">
              <div className="flex items-center flex-row gap-4">
                <img srcSet={img19} className="" />
                <div className="flex items-center place-content-center ">
                  +91 6284253050
                </div>
              </div>

              <div className="flex flex-row gap-4">
                <img srcSet={img20} />
                <div className="flex items-center place-content-center ">
                  teaminfoarbol@gmail.com
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <img srcSet={img21} />
                <div className="flex items-center place-content-center ">
                  Adjoining Gyan Honda Agency, Near Bus Stand, Faridkot, PB,
                  151203.
                </div>
              </div>
            </div>
            <div className="flex sm:self-center md:self-start flex-row md:mt-8 gap-4">
              <div className="">
                <a href="https://www.facebook.com/infoarbol">
                  <img srcSet={img22} className=" cursor-pointer " />
                </a>
              </div>
              <div className="">
                <a href="https://www.instagram.com/infoarbol?igsh=dmZwZzNlbzVqZ2Fv">
                  <img srcSet={img23} className=" cursor-pointer " />
                </a>
              </div>
              <div className="">
                <a href="https://youtube.com/@infoarbol?si=ZAYvtoJTUQowoGgz">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{ color: "red", fontSize: "30px" }}
                  />
                </a>
              </div>
              <div className="">
                <a href="https://x.com/infoarbol?t=InSESpefP_bTKyYV7z4NXA&s=08">
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    style={{ color: "white", fontSize: "30px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex py-6 flex-col md:w-[65%] px-8 ">
          <div className="grid grid-cols-2 w-full gap-4 ">
            <div className="flex flex-col grow shrink-0 items-start basis-0 w-full max-md:pr-5">
              <div className="self-start text-[#2D2D2D] font-semibold sm:text-[10px] md:text-mini ">
                First Name
              </div>
              <input
                type="text"
                style={{
                  borderBottom: "1px solid black",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                }}
                className="w-full h-9 text-lg leading-loose text-black px-3"
              />
            </div>
            <div className="flex flex-col grow shrink-0 items-start basis-0 w-full max-md:pr-5">
              <div className="self-start text-[#2D2D2D] font-semibold sm:text-[10px] md:text-mini">
                Last Name
              </div>
              <input
                type="text"
                style={{
                  borderBottom: "1px solid black",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                }}
                className="w-full h-9 text-lg leading-loose text-black px-3"
              />
            </div>

            <div className="flex flex-col grow shrink-0 items-start basis-0 w-full max-md:pr-5">
              <div className="self-start text-[#2D2D2D] font-semibold sm:text-[10px] md:text-mini ">
                Email
              </div>
              <input
                type="text"
                style={{
                  borderBottom: "1px solid black",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                }}
                className="w-full h-9 text-lg leading-loose text-black px-3"
              />
            </div>
            <div className="flex flex-col w-full grow shrink-0 items-start basis-0  rounded-lg  max-md:pr-5">
              <div className="self-start text-[#2D2D2D] font-semibold sm:text-[10px] md:text-mini  ">
                Phone Number
              </div>
              <input
                type="text"
                style={{
                  borderBottom: "1px solid black",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                }}
                className="w-full h-9 text-lg leading-loose text-black px-3"
              />
            </div>
          </div>
          <div className="flex sm:mt-8 md:mt-16 flex-col shrink-0 items-start basis-0 w-full max-md:pr-5">
            <div className="self-start text-[#2D2D2D] font-semibold  sm:text-[10px] md:text-mini ">
              Message
            </div>
            <textarea
              style={{
                borderBottom: "1px solid black",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
              }}
              placeholder="Write your message.."
              className="w-full h-[45px] text-lg leading-loose  text-black  bg-white"
            />
          </div>
          <div className="md:self-end sm:self-center sm:mt-8 md:mt-4 bg-[#1D1C1C] px-20 py-3 text-mini  font-normal tracking-widest font-Poppins leading-none text-center text-white uppercase whitespace-nowrap  max-md:px-5 cursor-pointer">
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card11;
