import React from "react";
import img12 from "../../assets/img12.svg";
const Blog = () => {
  const Cards1 = [
    {
      img: img12,
      imageTitle: "NEWS",
      title:
        "Class adds $30 million to its balance sheet for a Zoom-friendly edtech solution",
      description:
        "Class, launched less than a year ago by Blackboard co-founder Michael Chasen, integrates exclusively...",
    },
  ];
  const Cards2 = [
    {
      img: img12,
      imageTitle: "PRESS RELEASE",
      title:
        "Class Technologies Inc. Closes $30 Million Series A Financing to Meet High Demand",
      description:
        "Class Technologies Inc., the company that created Class,...",
    },
    {
      img: img12,
      imageTitle: "NEWS",
      title:
        "Class Technologies Inc. Closes $30 Million Series A Financing to Meet High Demand",
      description:
        "Zoom was never created to be a consumer product. Nonetheless, the...",
    },
    {
      img: img12,
      imageTitle: "NEWS",
      title:
        "Former Blackboard CEO Raises $16M to Bring LMS Features to Zoom Classrooms",
      description:
        "This year, investors have reaped big financial returns from betting on Zoom...",
    },
  ];
  return (
    <div id="blog" className="w-full overflow-x-hidden">
      <div className="w-[90%]  mx-auto ">
        <div className="flex flex-col ">
          <div
            style={{ borderBottom: "2px solid #2F327D" }}
            className="text-poppins w-fit self-center text-[#2F327D] font-semibold text-5xl text-center my-3 "
          >
            Lastest News and Resources
          </div>
          <div className="flex sm:gap-2 md:gap-0 py-12 sm:flex-col md:flex-row">
            <div className="md:w-[40%] font-poppins">
              <div className="flex gap-2 flex-col md:w-[85%] mx-auto">
                <div className="">
                  <img srcSet={Cards1[0].img} className="w-full" />
                </div>
                <div className="bg-[#49BBBD] rounded-[50px] w-fit px-4 py-[2px] text-white ">{Cards1[0].imageTitle}</div>
                <div className="text-[16px] font-semibold text-[#252641]">
                  {Cards1[0].title}
                </div>
                <div className="text-[15px] text-[#696984]">
                  {Cards1[0].description}
                </div>
                <div  style={{ borderBottom: "1px solid #696984" }} className="w-fit cursor-pointer text-[#696984] text-sm  " >
                  Read More
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 md:w-[60%] ">
              {Cards2.map((card, index) => (
                <div
                  key={index}
                  className="flex md:px-3 py-2 md:w-[90%] gap-4 sm:flex-col md:flex-row"
                >
                  <div className="flex">
                    <img
                      srcSet={card.img}
                      className="sm:w-full md:w-[200px] rounded-[8px]"
                    />
                  </div>
                  <div className="flex font-poppins gap-[10px] flex-col">
                    <div className="text-[14px] font-semibold text-[#252641] ">
                      {card.title}
                    </div>
                    <div className="text-[13px] text-[#696984] ">
                      {card.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
