import React from "react";

const OurService = () => {
  const Cards = [
    {
      title: "Comprehensive guidance",
      description:
        "A variety of evaluations are available on InfoArbol career counseling online to help you identify your areas of strength and growth. These insights assist you in making well-informed decisions regarding your career, guaranteeing that you are headed in the proper direction from the start.",
    },
    {
      title: "Extensive Options",
      description:
        "A variety of evaluations are available on InfoArbol career counseling online to help you identify your areas of strength and growth. These insights assist you in making well-informed decisions regarding your career, guaranteeing that you are headed in the proper direction from the start.",
    },
    {
      title: "Improved Plans",
      description:
        "For any of your career questions, Career counselling has the solutions. We work dedicatedly to create a career plan that aligns with your goals. InfoArbol career counselling for students can assist you in finding the ideal course of study, looking into internships, and getting ready for interviews.",
    },
    {
      title: "Success Stories",
      description:
        "The positive effects of career counselling online on the lives of numerous people are exemplified by our success stories. Our customers' accomplishments motivate us, and we're thrilled to be a part of their success stories, whether they're securing their dream positions or scaling new professional heights.",
    },
    {
      title: "Stay Connected",
      description:
        "Be a part of the InfoArbol career counsellor family, Get guidance, exchange experiences, and remain up to date on the ever evolving field of careers.",
    },
    {
      title: "Your Future Awaits",
      description:
        "The significance of your future can't be compromised. Infoarbol career counselling services is your trusted partner on the path to a brighter, more promising future. Let us help you make informed career choices, unlock your potential, and set you on the trajectory to success.",
    },
  ];
  return (
    <div id="services" className="w-full py-12 relative overflow-x-hidden">
      <div className="flex relative flex-col sm:w-[95%] md:w-[90%]  mx-auto px-8 bg-[#F8F7F3] py-12 rounded-[34px] shadow-[-3px_3px_4px_rgba(0,0,0,0.25)]">
        <div className="bg-[#5B61EB] sm:hidden  top-[3%] left-[5%] md:block md:h-[120px] absolute opacity-[95%] blur-[2px] md:w-[120px] rounded-[50%] "></div>
        <div className="bg-[#33D9EF] sm:hidden top-[27%] left-[80%] md:block md:h-[30px] absolute opacity-[95%] blur-[2px] md:w-[30px] rounded-[50%] "></div>
        <div className="bg-[#33EFA0] sm:hidden top-[55%] left-[5%] md:block md:h-[70px] absolute opacity-[95%] blur-[2px] md:w-[70px] rounded-[50%] "></div>
        <div className="bg-[#F56666] sm:hidden top-[70%] left-[90%] md:block md:h-[25px] absolute opacity-[95%] blur-[2px] md:w-[25px] rounded-[50%] "></div>

        <div className="flex flex-col gap-3 ">
          <div className="flex flex-col gap-2 text-center font-poppins ">
            <div className=" font-extrabold  text-[#00CBB8] text-13xl ">
              <span
                style={{ borderBottom: "2px solid #2F327D" }}
                className="font-extrabold text-[#2F327D]"
              >
                Why&nbsp;
              </span>{" "}
              <span style={{ borderBottom: "2px solid #00CBB8" }}>
                We Are Best
              </span>
            </div>
            <div className="text-[#696984]">
              This very extraordinary feature, can make learning activities more
              efficient
            </div>
          </div>
          <div className="flex flex-col">
            {Cards.map((card, index) => (
              <div key={index} className="mt-6">
                <div className="flex gap-3 flex-col md:py-3 md:px-5 sm:py-2 ">
                  <div
                    style={{ borderBottom: "1px solid #2F327D" }}
                    className="text-[#2F327D] w-fit self-center text-center text-2xl font-semibold font-poppins"
                  >
                    {card.title}
                  </div>
                  <div className="font-poppins sm:text-center md:text-left text-[#696984]   ">
                    {card.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurService;
