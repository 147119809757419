import React from "react";
import img24 from "../../../assets/img24.svg";
const Card12 = () => {
  return (
    <div className="w-full overflow-x-hidden">
      <div className="bg-[#122246] w-full ">
        <div className="md:w-[30%] gap-12 py-12 mx-auto flex flex-col ">
          <div className="flex ml-12 gap-8 justify-center flex-row">
            <div className="px-6">
              <img srcSet={img24} className="h-14 w-14" />
            </div>
            <div
              className="text-white flex "
              style={{ borderLeft: "1px solid #626381" }}
            >
              <div className=" px-12 w-[70%] flex items-center place-content-center text-center font-semibold ">
                Connect with Us
              </div>
            </div>
          </div>
          <div className="flex  flex-col gap-2 ">
            <div className="text-[#B2B3CF] text-center">
              Subscribe to get our Newsletter
            </div>
            <div className="flex sm:gap-4 md:gap-2 sm:flex-col md:flex-row self-center text-center ">
              <div>
                <input
                  placeholder="Your Email"
                  style={{ border: "1px solid #83839A" }}
                  className="rounded-[80px]  bg-transparent placeholder-[#83839A] placeholder-poppins pl-4 border-none h-9"
                />
              </div>
              <div className="md:self-end sm:self-center bg-[#49BBBD] px-8 py-[10px] sm:text-[13px] md:text-mini rounded-[60px]  font-normal tracking-widest font-Poppins leading-none text-center text-white uppercase whitespace-nowrap  max-md:px-5 cursor-pointer">
                Subscribe
              </div>
            </div>
          </div>
          <div className="flex gap-2 text-[#B2B3CF] flex-col">
            <div className="flex text-center flex-row">
              <div style={{ borderRight: "1px solid #626381" }} className="w-[25%] ">Careers</div>
              <div style={{ borderRight: "1px solid #626381" }} className="w-[35%]">Privacy Policy</div>
              <div className="w-[40%] ">Terms & Condition</div>
            </div>
            <div className="flex text-center self-center">© 2023 InfoArbol.in</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card12;
