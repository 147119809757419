import React from "react";
import img25 from "../../../assets/img25.svg";
import img30 from "../../../assets/img30.svg";
import img26 from "../../../assets/calendar1.svg";
import img27 from "../../../assets/email21.svg";
import img28 from "../../../assets/Group9.svg";
import img29 from "../../../assets/img29.svg";
import { useState, useEffect } from "react";
const Card1 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update the state when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div id="home" className="relative overflow-x-hidden overflow-hidden w-full">
      {windowWidth >= 768 ? (
        // For md and lg screens
        <img srcSet={img25} className="w-screen" />
      ) : (
        // For sm screens
        <img srcSet={img30} className="h-full w-full " />
      )}
      <div className="absolute inset-0 font-open-sans flex flex-row gap-24 md:justify-center md:items-center text-white">
        {windowWidth >= 768 ? (
          <div className="w-[70%] inset-0 bg-opacity-[20%] backdrop-blur-[4px] rounded-[12px] bg-[#6F6C6C82]">
            <div className="flex h-full flex-row">
              <div className="flex px-8 py-12 h-full font-lato text-white flex-col">
                <div className="text-[#EAEFF4] text-[40px] w-[330px] font-bold inline-flex flex-wrap">
                  <span className="Text1 whitespace-nowrap">
                    InfoArbol - Tree
                  </span>{" "}
                  &nbsp;
                  <span className="Text2 whitespace-nowrap">of</span>&nbsp;
                  <span className="Text3 ml-[-10px] whitespace-nowrap">
                    Information
                  </span>
                </div>
                <div className="mt-24 gap-6 flex justify-end items-end flex-col">
                  <div className="text-xl">
                    Explore the world full of opportunities
                  </div>
                  <div className="md:self-start mx-3 sm:self-center sm:mt-8 md:mt-4 bg-[#3A41E3] rounded-[50px] px-12 py-3 text-mini font-normal tracking-widest font-Poppins leading-none text-center text-white uppercase whitespace-nowrap max-md:px-5 cursor-pointer">
                    JOIN US
                  </div>
                </div>
              </div>
              <div className="flex xl:ml-[170px] xxl:ml-[320px] z-10 items-center justify-center">
                <div className="bg-white gap-2 px-3 py-2 backdrop-blur-[12px] opacity-[80%] rounded-[12px] flex flex-row">
                  <div className="bg-[#23BDEE] rounded-[8px] flex items-center place-content-center">
                    <img
                      srcSet={img26}
                      className="p-1 text-center flex items-center place-content-center"
                    />
                  </div>
                  <div className="flex w-[120px] flex-col px-2">
                    <div className="text-[#595959] text-[16px] font-semibold">
                      50k
                    </div>
                    <div className="text-[#545567] text-mini">
                      Assisted Student
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="overflow-y-hidden ml-[80px] hero-image xl:ml-[10px] h-[550px]  HeroAnimation1"
                srcSet={img29}
              />
              <div className="flex absolute xxl:right-[-5%] right-[-18%] top-[70%] z-10 items-center justify-center">
                <div className="bg-white gap-2 px-3 py-2 backdrop-blur-[12px] opacity-[80%] rounded-[12px] flex flex-row">
                  <div className="bg-[#F88C3D] rounded-[8px] flex items-center place-content-center">
                    <img
                      srcSet={img27}
                      className="p-1 text-center flex items-center place-content-center"
                    />
                  </div>
                  <div className="flex flex-col px-2">
                    <div className="text-[#595959] text-[16px] font-semibold">
                      Congratulations
                    </div>
                    <div className="text-[#545567] text-mini">
                      Your admission completed
                    </div>
                  </div>
                </div>
              </div>
              <img
                srcSet={img28}
                className="text-center absolute right-[-16%] top-[15%] h-24 w-24 rounded-[16px] flex items-center place-content-center"
              />
            </div>
          </div>
        ) : (
          <div className="w-[100%]">
            <div className="px-8 relative flex flex-col  mt-[130px]">
              <div className="text-[#EAEFF4] text-[36px] sm:leading-8 md:leading-0 gap-3 items-start place-content-start font-bold inline-flex flex-wrap">
                <span className="Text1 whitespace-nowrap">
                  InfoArbol - Tree
                </span>
                <span className="Text2 whitespace-nowrap">of</span>
                <span className="Text3  whitespace-nowrap">Information</span>
              </div>
              <img
                className="overflow-y-hidden custom-height  self-center text-center items-center px-auto mx-auto justify-center HeroAnimation2"
                srcSet={img29}
              />
              <div className="flex absolute top-[70%] z-10 items-center justify-center">
                <div className="bg-white gap-2 px-2 py-1 backdrop-blur-[12px] opacity-[80%] rounded-[12px] flex flex-row">
                  <div className="bg-[#23BDEE] rounded-[8px] flex items-center place-content-center">
                    <img
                      srcSet={img26}
                      className="p-1 text-center flex items-center place-content-center"
                    />
                  </div>
                  <div className="flex flex-col px-2">
                    <div className="text-[#595959] text-[15px] font-semibold">
                      50k
                    </div>
                    <div className="text-[#545567] text-[14px]">
                      Assisted Student
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex absolute top-[90%] left-[40%] z-10 items-center justify-center">
                <div className="bg-white gap-2 px-2 py-1 backdrop-blur-[12px] opacity-[80%] rounded-[12px] flex flex-row">
                  <div className="bg-[#F88C3D] rounded-[8px] flex items-center place-content-center">
                    <img
                      srcSet={img27}
                      className="p-1 text-center flex items-center place-content-center"
                    />
                  </div>
                  <div className="flex flex-col px-2">
                    <div className="text-[#595959] text-[15px] font-semibold">
                      Congratulations
                    </div>
                    <div className="text-[#545567] text-[14px]">
                      Your admission completed
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-[#F88C3D] absolute top-[60%] left-[75%] rounded-[8px] flex items-center place-content-center">
                <img
                  srcSet={img27}
                  className="p-1 text-center flex items-center place-content-center"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card1;
