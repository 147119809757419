import React from "react";
import img8 from "../../../assets/img8.svg";
const Card7 = () => {
  return (
    <div className="w-full overflow-x-hidden bg-white">
      <div style={{borderBottom:'1px solid black'}} className="w-[95%] py-6 md:pl-8 lg:pl-16 md:gap-18 lg:gap-24 xl:gap-30 mx-auto flex sm:flex-col md:flex-row">
        <div className="flex md:gap-3  text-left place-content-center flex-col ">
          <div className="text-left text-[#2F327D]" >
            <h1>Who Are InfoArbol</h1>
          </div>
          <div className="lg:w-[90%] text-left leading-[27px] md:text-[18px] text-[#696984] " >
            <p>
              Founded by a 23-year-old electrical engineer dissatisfied with his
              career, InfoArbol was born from a passion for coding and business.
              Despite pursuing electrical engineering due to external advice, he
              discovered his true interests lay elsewhere. Realizing many others
              faced similar dissatisfaction from choosing careers without
              knowing their true interests, he created InfoArbol—a tree of
              information to help people make informed career choices.
            </p>
          </div>
        </div>
        <div className="flex text-center place-content-center">
          <img
            srcSet={img8}
            className="md:h-[500px] md:w-[500px] lg:h-[550px] lg:w-[550px] xl:h-[600px] xl:w-[600px]  text-center flex self-center sm:h-[370px] sm:w-[370px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Card7;
