import React from "react";
import img7 from "../../../assets/img7.svg";
import img35 from '../../../assets/img35.svg'
const Card5 = () => {
  return (
    <div className="bg-white overflow-x-hidden w-full">
      <div className="md:w-[85%] px-9 bg-[#112F69] sm:w-[95%] mx-auto">
        <div className="flex items-center place-content- md:py-6 lg:gap-8 sm:flex-col font-poppins text-white lg:flex-row">
          <div className="md:px-3 lg:px-6 leading-65">
            <div className="lg:w-[80%]">
              <hi className="text-[#DCE2EE] text-5xl  font-bold leading-9 tracking-[1px] ">
                Why Choose InfoArbol Career Counseling
              </hi>
            </div>
            <div>
              <p className="text-[#D0D0D8] pt-4 leading-[28px] tracking-[1px]">
                Choosing an online counseling for career guidance is no small
                decision, and we want to assure you that InfoArbol is the right
                choice for you. We believe that career guidance should be both
                informative and enjoyable, and we're here to make the process as
                stress-free and entertaining as possible.
              </p>
            </div>
          </div>
          <div className="">
            <img
              src={img35}
              className="md:h-[450px] md:w-[450px] sm:h-[300px] sm:w-[300px] object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card5;
